<template>
  <div>
    <div id="color">
      <p class="pt-10"></p>
      <v-img
        max-height="300"
        max-width="200"
        class="center"
        src="../assets/Logo-Radio-Trunk_blanco.png"
      />
      <v-card-text>
        <v-form>
          <TextFields
            :Value="account"
            Label="Usuario"
            :Fn="updateAccount"
            Type="text"
            :Enter="login"
            :Dark="true"
          />
          <PasswordFields
            :Value="password"
            :Show="isVisiblePassword"
            Message="At least 5 characters"
            Label="Contraseña"
            :Fn="updatePassword"
            :appendFn="updateIsVisiblePassword"
            :Enter="login"
            :Dark="true"
          />
        </v-form>
      </v-card-text>
      <div class="container">
        <v-btn
          @click.prevent="login"
          outlined
          rounded
          style="margin: auto"
          dark
          width="200px"
          class="center"
          >Login</v-btn
        >
      </div>
    </div>
    <div
      class="backImage"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import TextFields from "../components/StandardizedComponents/textFields";
import PasswordFields from "../components/StandardizedComponents/passwordFields";
//import Snackbar from '../components/Dialogs/Notifications/snackbar'
export default {
  components: {
    TextFields,
    PasswordFields,
    //Snackbar
  },
  computed: {
    ...mapState("Login", [
      "account",
      "password",
      "containerStyle",
      "isVisiblePassword",
    ]),
  },
  methods: {
    ...mapActions("Login", ["login"]),
    ...mapMutations("Login", [
      "updatePassword",
      "updateAccount",
      "updateIsVisiblePassword",
    ]),
  },
  created() {
    this.$store.dispatch("Login/getRandom");
    this.$store.commit("Login/reziseBackground");
    /*window.addEventListener("resize", function () {
      location.reload();
    });*/
  },
};
</script>

<style scoped>
#color {
  position: absolute;
  background: black;
  min-width: 280px;
  height: 100%;
}
.container {
  width: 100%;
  display: grid;
}
.center {
  margin: 0px auto;
}
.backImage{
  background-image:url('../assets/backImage2.jpg');
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  background-size: cover;
  background-position: cover;
  right: 0;
  bottom: 0;
  left: 435px;
  top: 0;
}
@media (max-width: 490px) {
  .backImage{
    display: none;
  }
  #color{
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 490px) {

  #color{
    min-width: 440px;
  }
}
</style>